// Header

.fp-viewing-1, .fp-viewing-2, .fp-viewing-3, .fp-viewing-4, .fp-viewing-5, .fp-viewing-6, .fp-viewing-7 {
  .header {
    position: fixed;
    &:before {
      height: 100%; }
    ul {
      &:before {
        display: none; } }
    .sub-menu {
      background-image: linear-gradient(45deg,#008eff 0,#0090ff 28%,#009eff 94%,#00a2ff 100%);
      padding-top: 15px; } }
  .header_wrap {
    &__lang {
      ul {
        li {
          position: relative;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 33px;
            border-radius: 17px;
            background-color: white;
            color: $blue_dark;
            font-family: $book;
            font-size: 15px;
            font-weight: 300;
            text-transform: uppercase;
            transition: 0.3s;
            &:hover {
              transition: 0.3s;
              background-color: $blue_dark;
              color: white; } } } } } } }

.header__scroll {
  .header_wrap {
    &__lang {
      ul {
        li {
          position: relative;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 33px;
            border-radius: 17px;
            background-color: white;
            color: $blue_dark;
            font-family: $book;
            font-size: 15px;
            font-weight: 300;
            text-transform: uppercase;
            transition: 0.3s;
            &:hover {
              transition: 0.3s;
              background-color: $blue_dark;
              color: white; } } } } } } }

.header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 2;
  height: 70px;
  display: flex;
  align-items: center;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: 0.3s;
    background-image: linear-gradient(66deg, #062bff 0%, #0562ff 28%, #03edff 94%, #03fbff 100%);
    z-index: -1; }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &__scroll {
    position: fixed;
    z-index: 2;
    &:before {
      height: 100%; }
    ul {
      &:before {
        display: none; } }
    .sub-menu {
      background-image: linear-gradient(45deg,#008eff 0,#0090ff 28%,#009eff 94%,#00a2ff 100%);
      padding-top: 15px; } } }


.header_wrap {
  &__nav {
    ul {
      display: flex;
      padding: 0 40px;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 3px;
        left: 0;
        bottom: -13px;
        background-image: linear-gradient(45deg, #062bff 0%, #0562ff 28%, #03edff 94%, #03fbff 100%); }
      .current-menu-item {
        a {
          font-family: $bold;
          font-weight: 900; } }
      li {
        position: relative;
        a {
          color: white;
          font-family: $book;
          font-size: 15px;
          font-weight: 300;
          margin: 0 7px;
          text-transform: uppercase;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            font-weight: 700;
            color: #00d4ff; } }
        ul {
          display: none;
          position: absolute;
          width: 100%;
          bottom: -91px;
          flex-direction: column;
          padding: 15px 0;
          &:before {
            display: none; }
          li {
            text-align: center;
            a {
              margin: 0;
              text-transform: none; } } } } } }
  &__lang {
    display: flex;
    a {
      color: white;
      font-family: $book;
      font-size: 15px;
      font-weight: 300;
      margin: 0 7px;
      text-transform: uppercase;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        color: $blue_dark; } }
    ul {
      display: flex;
      .current-menu-item {
        position: relative;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 33px;
          border-radius: 17px;
          background-color: white;
          color: $blue_dark;
          font-family: $bold!important;
          font-size: 15px;
          font-weight: 900!important;
          text-transform: uppercase;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            background-color: $blue_dark;
            color: white; } } }
      li {
        &:last-child {
          a {
            &:before {
              display: none; } } }
        a {
          position: relative;
          display: flex;
          align-items: center;
          &:hover {
            color: #00d4ff; }
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 75%;
            right: -7px;
            background-color: #ddd; } } } } }
  &__mobile {
    display: none; } }

.overlay {
  &_active {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%; } }
