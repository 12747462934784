$r-768: 768px;
$r-992: 992px;
$r-1024: 1024px;
$r-1200: 1200px;

body {

  // break max-1200

  @media only screen and (max-width: $r-1200 - 1) {
    .container {
      padding: 0 30px; }
    .resources_info_wrap {
      &__img {
        text-align: left; } } }

  // break max-992
  @media only screen and (max-width: $r-992) {
    .header_wrap {
      &__logo {
        img {
          height: 26px; } }
      &__nav, &__lang {
        display: none; }
      &__mobile {
        display: block; }
      &__nav {
        width: 100%;
        position: absolute;
        left: 0;
        top: 80px;
        padding: 20px 15px;
        background-image: linear-gradient(-72deg,#03fbff 0,#058fff 61%,#062bff 100%);
        ul {
          flex-direction: column;
          &:before {
            display: none; }
          li {
            ul {
              display: block;
              position: initial;
              li {
                text-align: left;
                margin: 0 20px; } } } } } } }

  // break max-992
  @media only screen and (max-width: $r-768 - 1) {

    // Global
    .home_header {
      background-image: inherit; }

    #video-background {
      display: none; }

    .header__scroll {
      position: inherit;
      display: none; }

    .show_mobile {
      display: block; }

    .show_desctop {
      display: none; }

    .s_title {
      font-size: 20px;
      line-height: 25px; }

    .fp-tableCell, .fp-section.fp-table, .fp-scrollable {
      display: block;
      height: auto!important; }

    .careers_header, .about_header, .contacts_header, .events_header, .home_header, .news_header, .product_header, .resources_header, .tech_header {
      height: 666px;
      background-position: top;
      &__wrapper {
        .title {
          font-size: 40px;
          line-height: 40px; } } }

    // Team
    .about_team {
      margin-bottom: 30px;
      p {
        font-size: 20px;
        line-height: 25px; } }
    .about_team {
      &_item {
        width: 100%;
        min-height: 390px;
        overflow: hidden;
        img {
          height: auto; } }
      &__title {
        font-size: 20px;
        line-height: 25px; } }
    .about_team_directors_item {
      width: 100%;
      min-height: 390px;
      overflow: hidden;
      img {
        height: auto; } }
    .about_team_text p {
      font-size: 16px;
      line-height: 18px; }
    .about_investors_item {
      width: 100%;
      margin-bottom: 30px;
      img {
        width: 80%; } }

    // Careers
    .header {
      padding-top: 35px; }
    .careers_single {
      padding-top: 0; }
    .careers_tabs {
      &__link {
        display: none; }
      &__content {
        width: 100%;
        .s_text {
          p {
            padding: 0 30px; } } } }
    .careers_tabs {
      &__wrapper {
        margin-bottom: 20px; } }
    .careers_positions {
      padding-bottom: 0;
      border-bottom: 1px solid #0624ff;
      margin: 0 0 30px 0;
      &__item {
        width: 75%;
        margin: 0 auto 50px auto; } }
    .careers_content {
      &__form {
        padding: 0 30px;
        .title {
          font-size: 12px;
          text-align: left; }
        form {
          span {
            width: 100%;
            margin-bottom: 25px;
            &:nth-child(4) {
              width: 100%; } }
          .cv_label {
            justify-content: center; }
          .file-404 {
            margin-bottom: 0; }
          div.wpcf7-validation-errors {
            font-size: 8px; }
          .wpcf7-mail-sent-ok {
            font-size: 8px; } } } }
    .careers_padding {
      padding-top: 0; }

    .careers_content__form form .gform_body ul li {
      width: 100% !important;
      &:nth-child(3) {
        width: 100% !important; } }

    .careers_content__form form .gform_footer {
      position: relative;
      text-align: left;
      margin-top: 0!important; }

    // Contact us
    .contacts_form {
      form {
        padding: 0 30px; }
      label:nth-child(3), label:nth-child(4), label:nth-child(5), label:nth-child(6), label:nth-child(7) {
        width: 100% !important; } }
    .address_item {
      flex-wrap: wrap;
      &__info, &__map {
        width: 100%; }
      &__info {
        text-align: center;
        padding: 40px 30px 50px 30px; } }

    .contacts_form form li:nth-child(2), .contacts_form form li:nth-child(3), .contacts_form form li:nth-child(4), .contacts_form form li:nth-child(5), .contacts_form form li:nth-child(6), .contacts_form form li:nth-child(7) {
      width: 100% !important; }

    // Events
    .events_list_item {
      width: 260px;
      margin: 0 auto 100px auto; }

    // Home
    .home_header {
      margin-bottom: 55px;
      &__wrapper {
        min-height: 666px;
        .title {
          margin-bottom: 20px; }
        p {
          font-size: 18px;
          line-height: 20px;
          text-align: left; } } }
    .home_car {
      display: flex;
      flex-direction: column;
      padding-bottom: 55px;
      .container {
        order: 2; }
      p {
        padding-top: 0; }
      &__text {
        margin-top: 30px; }
      &__btns {
        margin-top: 35px; } }
    .home_tabs {
      flex-wrap: wrap; }
    .home_more {
      .title {
        text-align: left; } }
    .home_logos {
      padding-top: 0;
      .swiper-logos {
        .swiper-button-prev, .swiper-button-next {
          img {
            width: 50%; } } } }

    // News
    .news_list {
      .container {
        padding: 0; } }
    .news_list_item {
      width: 100%;
      &__img {
        order: 1; }
      &__text {
        padding: 0 60px;
        p {
          margin-bottom: 20px; } }
      &__info {
        padding: 0 60px;
        margin-bottom: 20px;
        span {
          display: inline-flex;
          position: relative;
          padding-left: 12px;
          &:before {
            position: absolute;
            content: '/';
            left: 0; }
          &:before {
            display: block; } }
        p {
          margin-bottom: 0; } }
      &__btn {
        a {
          margin: 0 auto!important; } } }

    // Product

    .product_text {
      padding-bottom: 0;
      &__wrapper {
        margin: 0 auto; } }
    .product_content {
      .title {
        text-align: center; }
      .s_text {
        p {
          padding: 0; } } }
    .product_features_item {
      width: 46%;
      margin-bottom: 20px; }
    .product_numbers {
      &__item {
        margin: 0 0 20px 0;
        width: 100%; } }
    .product_download {
      padding-bottom: 50px;
      &_wrap {
        margin: 0 auto 50px auto; }
      &_wrap__item {
        p {
          width: 60%; }
        a {
          width: 40%; } }
      &__btns {
        flex-direction: column;
        a {
          margin-bottom: 20px; } } }

    // Resources

    .resources_header {
      &__wrapper {
        .title {
          text-align: center; } } }
    .resources_form {
      form {
        div.wpcf7-validation-errors {
          font-size: 8px; }
        .wpcf7-mail-sent-ok {
          font-size: 8px; }
        .gform_body {
          ul {
            li:first-child, li:nth-child(2), li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6) {
              width: 100% !important; } } } } }
    .modal.webinar {
      border-radius: 20px;
      .description {
        .title {
          font-size: 16px;
          line-height: 20px; } }
      form {
        div.wpcf7-validation-errors {
          font-size: 8px!important; }
        .wpcf7-mail-sent-ok {
          font-size: 8px!important; }
        .gform_body {
          ul {
            li:first-child, li:nth-child(2), li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6) {
              width: 100%!important;
              input {
                background-color: #f7f7f7; } } } }
        input[type="submit"] {
          background-color: #f7f7f7; } }
      .image.content {
        border-radius: 20px;
        padding: 50px 15px!important;
        background-color: #f7f7f7; } }

    // Technology
    .tech_text {
      &__wrapper {
        .title {
          margin-top: 0; } }
      img {
        margin-top: 0; }
      &__btns {
        align-items: center;
        flex-direction: column;
        margin: 50px 0;
        a {
          margin-bottom: 20px; } } }

    // Footer
    .footer__wrapper {
      margin-bottom: 50px; }
    .footer_right {
      width: 100%;
      margin-bottom: 50px;
      .title {
        font-size: 14px; }
      ul {
        justify-content: space-between;
        li {
          a {
            i {
              font-size: 40px;
              opacity: 0.6; } } } } }
    .footer_left {
      width: 100%;
      &__form {
        margin-bottom: 50px;
        .title {
          font-size: 14px; } }
      form {
        div.wpcf7-validation-errors {
          font-size: 8px; }
        .wpcf7-mail-sent-ok {
          font-size: 8px; }
        .wpcf7-form-control-wrap {
          width: 60%;
          input {
            width: 100%; } }
        input[type='submit'] {
          background-color: $blue_dark;
          border-color: $blue_dark;
          color: white; }
        input[type='submit'] {
          width: 40%; } }
      &__nav {
        padding: 0 30px;
        ul {
          flex-wrap: wrap;
          li {
            width: 50%;
            padding-left: 40px;
            a {
              padding: 0!important; } } } } }
    .footer__copyright {
      flex-wrap: wrap;
      p {
        margin-right: 0!important;
        text-align: center; }
      ul {
        flex-direction: column;
        align-items: center;
        li {
          a {
            &:before {
              display: none; } } } }
      .custom-html-widget {
        order: 2;
        width: 100%; }
      .menu-privacy-container {
        order: 1;
        width: 100%; } } } }
