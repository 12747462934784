// Technology header

.tech_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 55px;
  position: relative;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      text-align: center;
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } }
  #video-background {
    height: 500px; } }

// Technology text

.tech_text {
  img {
    margin: 0 0 20px 0;
    width: 100%; }
  &__wrapper {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    p {
      margin-bottom: 20px; }
    .title {
      text-transform: none;
      text-align: center;
      margin: 70px 0 30px 0; } }
  &__video {
    video {
      width: 100%;
      max-width: 100%; } }
  &__btns {
    display: flex;
    justify-content: center;
    margin: 100px 0;
    a {
      margin: 0 5px;
      width: initial;
      padding: 0 30px; } } }

