// Product header

.product_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// Product text

.product_text {
  padding: 55px 0;
  &__wrapper {
    width: 800px;
    max-width: 100%;
    margin: 0 auto 55px auto;
    p {
      margin-bottom: 20px; } }
  img {
    width: 100%; } }

// Product tabs

.product_tabs__wrapper {
  position: relative;
  padding-bottom: 40px;
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(45deg, #062bff 0%, #0562ff 28%, #03edff 94%, #03fbff 100%); } }

.product_content {
  .title {
    color: black;
    font-family: $bold;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px; }
  p {
    margin-bottom: 15px; } }

.product_features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.product_features_item {
  text-align: center;
  width: 10%;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    height: 40px; }
  .title {
    font-size: 12px;
    font-family: $bold;
    font-weight: 500;
    margin-bottom: 5px; }
  p {
    font-size: 10px;
    font-family: $light;
    font-weight: 100; } }

.swiper-product {
  padding-bottom: 30px!important;
  .swiper-slide {
    img {
      width: 100%; } }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    margin: 0 6px!important;
    background-color: $blue_dark; }
  .swiper-pagination-bullet-active {
    background-color: rgba(6, 36, 255, 0.5); } }

.product_numbers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 55px;
  &__item {
    display: flex;
    align-items: flex-end;
    margin-right: 50px;
    .title {
      font-family: $bold;
      font-weight: 500;
      line-height: 1;
      color: $blue_dark;
      font-size: 50px;
      margin: 0 10px 0 0; }
    p {
      color: black;
      font-family: $bold;
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 5px!important; } } }

// Product download

.product_download {
  padding-bottom: 100px; }

.product_download_wrap {
  width: 800px;
  max-width: 100%;
  margin: 0 auto 100px auto;
  &__item {
    display: flex;
    align-items: center;
    background-image: linear-gradient(45deg, #062bff 0%, #0562ff 28%, #03edff 94%, #03fbff 100%);
    height: 40px;
    padding: 0 3px 0 30px;
    margin-bottom: 15px;
    p {
      height: 40px;
      width: 85%;
      max-width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      color: white;
      font-family: $bold;
      font-size: 16px;
      font-weight: 500;
      line-height: 12px; }
    a {
      width: 15%;
      height: 34px;
      font-size: 12px;
      font-family: $bold;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      color: rgba(6, 36, 255, 0.31);
      &:hover {
        transition: 0.3s;
        background-color: $blue_dark;
        color: white; } }
    .disabled {
      opacity: .4;
      cursor: default;
      pointer-events: none; } } }

.product_download__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin: 0 5px;
    width: auto;
    padding: 0 30px; } }
