@font-face {
  font-family: 'Arboria-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Arboria-Light'), url('../fonts/Arboria-Light.woff') format('woff'); }

@font-face {
  font-family: 'Arboria-Book';
  font-style: normal;
  font-weight: normal;
  src: local('Arboria-Book'), url('../fonts/Arboria-Book.woff') format('woff'); }

@font-face {
  font-family: 'Arboria-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Arboria-Medium'), url('../fonts/Arboria-Medium.woff') format('woff'); }

@font-face {
  font-family: 'Arboria-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Arboria-Bold'), url('../fonts/Arboria-Bold.woff') format('woff'); }

@font-face {
  font-family: 'Arboria-Black';
  font-style: normal;
  font-weight: normal;
  src: local('Arboria-Black'), url('../fonts/Arboria-Black.woff') format('woff'); }
