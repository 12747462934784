// Careers header

.careers_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin-bottom: 55px;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      text-align: center;
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// Careers tabs

.careers_tabs {

  .container {
    display: flex; }
  &__img {
    margin-bottom: 30px;
    img {
      width: 100%;
      height: auto; } }
  &__wrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  &__link {
    width: 20%;
    max-width: 100%;
    margin-bottom: 50px; }
  &__content {
    width: 80%;
    max-width: 100%; } }

.careers_content {
  &__images {
    margin-bottom: 45px;
    img {
      width: 100%; } }
  &__list_images {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    img {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
      &:nth-child(2), &:nth-child(3) {
        width: 49%; } } }
  p {
    margin-bottom: 20px; }
  &__form {
    .title {
      text-align: center;
      color: black;
      font-family: $book;
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 25px; }
    p {
      text-align: center;
      color: black;
      font-family: $book;
      font-size: 15px;
      font-weight: 700; }
    .gform_confirmation_message {
      text-align: center;
      color: black; }
    form {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .ajax-loader {
        display: none!important; }
      .wpcf7-not-valid {
        border-color: red; }
      span.wpcf7-not-valid-tip {
        display: none; }
      div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
        width: 100%;
        text-align: center;
        display: block;
        position: absolute;
        bottom: 0;
        font-family: $book;
        margin: 5px 0 0 0;
        font-size: 12px;
        color: $blue_dark;
        border-color: red; }
      .wpcf7-mail-sent-ok {
        width: 100%;
        max-width: 100%;
        text-align: center;
        display: block;
        position: absolute;
        bottom: 0;
        font-family: $book;
        margin: 5px 0 0 0;
        font-size: 12px;
        color: $blue_dark;
        border-color: green; }
      .gform_heading {
        display: none!important; }
      .gform_footer {
        position: absolute;
        text-align: right;
        padding: 0!important;
        margin: 15px 0 0 0!important;
        width: 100%;
        input {
          width: 111px!important;
          height: 33px;
          color: #0624ff;
          font-family: $book;
          border-radius: 17px;
          font-size: 15px!important;
          font-weight: 300;
          cursor: pointer;
          margin: 0 auto 33px auto;
          text-transform: none;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            background-color: $blue_dark;
            color: white; } } }
      .gform_body {
        .validation_message {
          display: none; }
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .gfield_error {
            background-color: initial!important;
            margin: 0 0 30px 0!important;
            padding: 0!important;
            border: 0!important; }
          li {
            margin: 0 0 30px 0!important;
            padding-right: 0!important;
            width: 30% !important;
            &:nth-child(3) {
              width: 40% !important; }
            &:nth-child(4) {
              width: 100% !important; } }
          .ginput_container {
            width: 100% !important;
            margin: 0!important;
            span {
              padding: 0!important; }
            input {
              width: 100% !important;
              margin: 0!important; } } } }
      .validation_error {
        display: none; }
      .validation_message {
        padding-top: 5px!important; }
      input {
        width: 100%;
        height: 40px;
        border: 1px solid #0624ff;
        background-color: inherit;
        text-align: center;
        color: black;
        font-family: $book;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        outline: 0;
        &::placeholder {
          color: black;
          font-family: $book;
          font-size: 12px;
          font-weight: 700;
          line-height: 12px; } }
      input[type="email"] {
        width: 100%; }
      input[type="file"] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95px!important;
        border: 0!important;
        height: 21px;
        color: #0624ff;
        font-family: $book;
        font-size: 10px;
        font-weight: 300;
        cursor: pointer;
        margin: 15px 0 0 0;
        text-transform: none;
        transition: 0.3s; } } } }

.careers_positions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 100px 0;
  &__item {
    width: 100%;
    max-width: 100%;
    text-align: center;
    .title {
      color: $blue_dark;
      font-family: $book;
      font-size: 20px;
      font-weight: 300;
      line-height: 25px;
      text-transform: uppercase;
      margin-bottom: 40px;
      text-align: left; }
    ul {
      display: flex;
      flex-direction: column;
      li {
        text-align: left;
        a {
          color: black;
          font-family: $book;
          font-size: 16px;
          font-weight: 300;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            color: $blue_dark; } } } } } }

.careers_padding {
  padding-top: 100px; }
