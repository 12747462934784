// Resources header

.resources_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// Resources info

.resources_info {
  padding: 55px 0;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; } }

.description__video {
  display: none;
  width: 100%;
  .description {
    text-align: center;
    iframe {
      max-width: 100%; } } }

.resources_info_wrap {
  &__text {
    width: 510px;
    margin-bottom: 20px;
    .title {
      color: black;
      font-family: $bold;
      font-size: 20px;
      font-weight: 300;
      line-height: 25px;
      margin-bottom: 15px; }
    p, ul li {
      color: black;
      font-family: $book;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px; }
    p {
      margin-bottom: 15px; }
    a {
      display: inline-flex;
      width: initial;
      padding: 0 30px;
      margin-top: 25px; }
    ul {
      margin-left: 20px;
      li {
        list-style: disc; } } }
  &__img {
    width: 650px;
    max-width: 100%;
    text-align: right; } }

// Resources download

.resources_download {
  &__title {
    margin: 100px 0 30px 0;
    .title {
      text-align: center;
      text-transform: none; } } }

.resources_form {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 85px;
  .gform_confirmation_message {
    text-align: center;
    color: black;
    font-family: 'Arboria-Book';
    font-size: 30px;
    font-weight: 700;
    line-height: 35px; }
  .gform_heading {
    display: none!important; }
  .gform_footer {
    width: 100% !important;
    margin: 0!important;
    padding: 0!important;
    text-align: center;
    input {
      color: $blue_dark;
      background-color: white;
      font-family: $bold;
      font-size: 15px!important;
      background-image: url("../img/gradient_btn.svg");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 30px;
      width: 170px!important;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.3s;
      z-index: 1;
      border: 0;
      cursor: pointer;
      margin: 0 auto;
      outline: 0;
      &:hover {
        background-color: $blue_dark;
        background-image: none;
        transition: 0.3s;
        color: white; } } }
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .ajax-loader {
      display: none!important; }
    .wpcf7-not-valid {
      border-color: red; }
    span.wpcf7-not-valid-tip {
      display: none; }
    div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
      width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      bottom: -40px;
      font-family: $book;
      margin: 5px 0 0 0;
      font-size: 12px;
      color: $blue_dark;
      border-color: red; }
    .wpcf7-mail-sent-ok {
      width: 100%;
      max-width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      bottom: -40px;
      font-family: $book;
      margin: 5px 0 0 0;
      font-size: 12px;
      color: $blue_dark;
      border-color: green; }
    .ginput_container {
      width: 100% !important;
      margin: 0!important;
      span {
        padding: 0!important; }
      input {
        width: 100%!important;
        margin: 0!important; } }
    .validation_error {
      display: none; }
    .validation_message {
      padding-top: 5px!important; }
    .gform_body {
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .gfield_error {
          width: inherit!important;
          margin: 0!important;
          padding: 0!important;
          border: 0!important; }
        li {
          margin: 0 0 15px 0!important;
          padding: 0!important;
          label {
            color: black;
            font-family: $bold;
            font-size: 16px;
            font-weight: 300;
            line-height: 25px;
            margin-bottom: 4px; }
          input {
            width: 100%;
            height: 40px;
            border: 1px solid black;
            outline: 0;
            padding-left: 15px; }
          &:first-child, &:nth-child(2) {
            width: 29% !important; }
          &:nth-child(3) {
            width: 39% !important; }
          &:nth-child(4), &:nth-child(5) {
            width: 49% !important; } } } }
    .gfield_error {
      width: inherit!important;
      margin: 0!important;
      padding: 0!important;
      border: 0!important; } } }

.resources_links {
  padding-bottom: 30px;
  .title {
    margin-bottom: 20px; }
  .product_download_wrap {
    margin: 0 auto 50px auto; } }

.modal.webinar, .modal.about_video {
  border-radius: 20px;
  background-color: #ffffff;
  .close {
    top: 10px!important;
    right: 10px!important;
    &:before {
      display: none; } }
  .description {
    width: 100%;
    max-width: 100%;
    padding: 50px 0 20px 0;
    p {
      &:last-child {
        margin-bottom: 0; } }
    .title {
      text-align: center;
      margin-bottom: 50px;
      font-size: 24px;
      text-transform: none; } }
  .gform_confirmation_message {
    text-align: center;
    color: black; }
  .gform_heading {
    display: none!important; }
  .gform_footer {
    width: 100% !important;
    margin: 0!important;
    padding: 0!important;
    text-align: center;
    input {
      color: $blue_dark;
      background-color: white;
      font-family: $bold;
      font-size: 15px!important;
      background-image: url("../img/gradient_btn.svg");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 30px;
      width: 170px!important;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.3s;
      z-index: 1;
      border: 0;
      cursor: pointer;
      margin: 0 auto;
      outline: 0;
      &:hover {
        background-color: $blue_dark;
        background-image: none;
        transition: 0.3s;
        color: white; } } }
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .ajax-loader {
      display: none!important; }
    .wpcf7-not-valid {
      border-color: red; }
    span.wpcf7-not-valid-tip {
      display: none; }
    div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
      width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      bottom: -40px;
      font-family: $book;
      margin: 5px 0 0 0;
      font-size: 12px;
      color: $blue_dark;
      border-color: red; }
    .wpcf7-mail-sent-ok {
      width: 100%;
      max-width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      bottom: -40px;
      font-family: $book;
      margin: 5px 0 0 0;
      font-size: 12px;
      color: $blue_dark;
      border-color: green; }
    .ginput_container {
      width: 100% !important;
      margin: 0!important;
      span {
        padding: 0!important; }
      input {
        width: 100%!important;
        margin: 0!important; } }
    .validation_error {
      display: none; }
    .validation_message {
      padding-top: 5px!important; }
    .gform_body {
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .gfield_error {
          width: inherit!important;
          margin: 0!important;
          padding: 0!important;
          border: 0!important; }
        li {
          margin: 0 0 15px 0!important;
          padding: 0!important;
          label {
            color: black;
            font-family: $bold;
            font-size: 16px;
            font-weight: 300;
            line-height: 25px;
            margin-bottom: 4px; }
          input {
            width: 100%;
            height: 40px;
            border: 1px solid black;
            outline: 0;
            padding-left: 15px; }
          &:first-child, &:nth-child(2) {
            width: 29% !important; }
          &:nth-child(3) {
            width: 39% !important; }
          &:nth-child(4), &:nth-child(5) {
            width: 49% !important; } } } }
    .gfield_error {
      width: inherit!important;
      margin: 0!important;
      padding: 0!important;
      border: 0!important; } } }

