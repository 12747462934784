// Careers single

.careers_single {
  padding: 40px 0 55px 0;
  &__back {
    margin-bottom: 40px;
    a {
      display: inline-flex;
      align-items: center;
      color: black;
      font-family: $bold;
      font-size: 16px;
      font-weight: 700;
      line-height: 53px;
      transition: 0.3s;
      img {
        margin-right: 16px; }
      &:hover {
        transition: 0.3s;
        color: $blue_dark; } } }
  &__content {
    width: 800px;
    max-width: 100%;
    margin: 0 auto 100px auto;
    h1, h2, h3, h4, h5, h6 {
      color: $blue_dark;
      font-size: 20px;
      font-family: $book;
      font-weight: 700;
      line-height: 25px; }
    ul {
      padding-left: 40px;
      li {
        list-style: disc;
        margin-bottom: 15px;
        color: black;
        font-family: $book;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px; } } }
  &__btn {
    a {
      margin: 0 auto; } } }
