// Contacts header

.contacts_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin-bottom: 55px;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// Contacts form

.contacts_form {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 85px;
  .s_title {
    margin-bottom: 50px; }
  .gform_confirmation_message {
    text-align: center;
    color: black; }
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .ajax-loader {
      display: none!important; }
    .wpcf7-not-valid {
      border-color: red; }
    span.wpcf7-not-valid-tip {
      display: none; }
    div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
      width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      bottom: -40px;
      font-family: $book;
      margin: 5px 0 0 0;
      font-size: 12px;
      color: $blue_dark;
      border-color: red; }
    .wpcf7-mail-sent-ok {
      width: 100%;
      max-width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      bottom: -40px;
      font-family: $book;
      margin: 5px 0 0 0;
      font-size: 12px;
      color: $blue_dark;
      border-color: green; }
    .gform_heading {
      display: none!important; }
    .gform_footer {
      width: 100% !important;
      margin: 0!important;
      padding: 0!important;
      text-align: center;
      input {
        color: $blue_dark;
        background-color: white;
        font-family: $bold;
        font-size: 15px!important;
        border: 1px solid $blue_dark;
        border-radius: 30px;
        width: 170px!important;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: 0.3s;
        z-index: 1;
        cursor: pointer;
        margin: 0 auto;
        outline: 0;
        &:hover {
          background-color: $blue_dark;
          background-image: none;
          transition: 0.3s;
          color: white; } } }
    .gform_body {
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .gfield_error {
      width: inherit!important;
      margin: 0!important;
      padding: 0!important;
      border: 0!important; }
    li {
      margin-bottom: 15px;
      label {
        display: flex;
        font-family: $bold;
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        margin-bottom: 4px; }
      input, select, textarea {
        width: 100% !important;
        height: 40px;
        border: 1px solid $blue_dark;
        outline: 0;
        padding-left: 15px;
        background-color: white; }
      &:first-child {
        width: 100% !important; }
      &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        width: 29% !important; }
      &:nth-child(8) {
        width: 100% !important;
        textarea {
          resize: none;
          height: 120px; } } }
    li {
      padding-right: 0!important;
      margin-top: 0!important; }
    .ginput_container {
      width: 100% !important;
      margin: 0!important;
      span {
        padding: 0!important; }
      input {
        width: 100%!important;
        margin: 0!important; } }
    .validation_error {
      display: none; }
    .validation_message {
      padding-top: 5px!important; } } }

// Contacts address

.contacts_address {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  .s_title {
    margin-bottom: 50px; } }

.address_item {
  display: flex;
  margin-bottom: 25px;
  &__info {
    width: 40%;
    background-color: #f2f2f2;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      color: $blue_dark;
      font-family: $book;
      font-size: 30px;
      font-weight: 700;
      line-height: 35px;
      margin-bottom: 18px; }
    p, a {
      color: $blue_dark;
      font-family: $book;
      font-size: 16px;
      font-weight: 300;
      line-height: 18px;
      margin-bottom: 15px; } }
  &__map {
    width: 60%;
    height: 430px;
    iframe {
      height: 430px; } } }
