// Events header

.events_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// Events list

.events_list {
  padding: 55px 0;
  &__title {
    margin-bottom: 80px;
    .title {
      text-align: center;
      text-transform: none; } }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1010px;
    max-width: 100%;
    margin: 0 auto; } }

.events_list_item {
  width: 245px;
  max-width: 100%;
  margin-bottom: 100px;
  &__block {
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: 16px;
    height: 100%; }
  &__logo {
    background-color: white;
    height: 40%;
    padding: 0;
    text-align: center;
    img {
      height: auto; } }
  &__info {
    height: 64%;
    background-color: #f2f2f2;
    padding: 30px 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    p {
      margin-bottom: 0!important;
      padding: 0 5px; } } }

.events_list_info {
  p {
    width: 100%;
    min-height: 115px; }
  .btn_blue {
    margin: 0 auto; }
  &__date {
    display: flex;
    align-items: center;
    color: black;
    font-family: $book;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    padding: 0 5px;
    margin-bottom: 20px; }
  &__place {
    color: #000;
    font-family: $book;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 5px;
    margin-bottom: 20px; }
  &__date {
    position: relative;
    color: $blue_dark;
    //&:before
    //  position: absolute
    //  content: ''
    //  right: -10px
    //  width: 2px
    //  height: 70%
 }    //  background-color: black
  &__btn {
    a {
      margin: 0 auto;
      width: 182px; } } }
