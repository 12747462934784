// Footer

.footer {
  padding: 30px 0;
  background-image: linear-gradient(-72deg, #03fbff 0%, #058fff 61%, #062bff 100%);
  &__logo {
    margin-bottom: 10px;
    img {
      width: 66px; } }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 70px; }
  &__copyright {
    display: flex;
    align-items: center;
    p {
      color: white;
      font-family: $book;
      font-size: 15px;
      font-weight: 300;
      line-height: 21.6px;
      margin: 0 20px 0 0!important; }
    ul {
      display: flex;
      li {
        &:last-child {
          a {
            &:before {
              display: none; } } }
        a {
          display: flex;
          align-items: center;
          padding: 0 10px;
          color: white;
          font-family: $book;
          font-size: 15px;
          font-weight: 300;
          line-height: 21.6px;
          position: relative;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            color: $blue_dark; }
          &:before {
            position: absolute;
            content: '';
            right: 0;
            height: 70%;
            width: 2px;
            background-color: white; } } } } } }

body {
  .footer_left {
    &__form {
      margin-bottom: 30px;
      .title {
        color: white;
        font-family: $bold;
        font-size: 12px;
        font-weight: 300;
        line-height: 29px;
        text-transform: uppercase;
        margin-bottom: 21px; }
      .gform_confirmation_message {
        color: white; }
      form {
        position: relative;
        display: flex;
        .ajax-loader {
          display: none!important; }
        .wpcf7-not-valid {
          border-color: red; }
        span.wpcf7-not-valid-tip {
          display: none; }
        div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
          width: 100%;
          text-align: center;
          display: block;
          position: absolute;
          font-family: $book;
          margin: 5px 0 0 0;
          font-size: 12px;
          color: white;
          border-color: red;
          bottom: -35px; }
        .wpcf7-mail-sent-ok {
          width: 100%;
          text-align: center;
          display: block;
          position: absolute;
          font-family: $book;
          margin: 5px 0 0 0;
          font-size: 12px;
          color: white;
          border-color: green;
          bottom: -35px; }
        .gform_heading {
          display: none!important; }
        .gform_footer {
          width: 111px!important;
          margin: 0!important;
          padding: 0!important; }
        .gform_body {
          ul {
            display: flex;
            align-items: center; }
          .gfield_error {
            margin: 0!important;
            padding: 0!important;
            border: 0!important; }
          li {
            height: 39px!important;
            width: 170px!important;
            padding-right: 0!important;
            margin-top: 0!important; }
          .ginput_container {
            width: 170px!important;
            height: 39px!important;
            margin: 0!important;
            span {
              padding: 0!important; }
            input {
              width: 170px!important;
              margin: 0!important; } } }
        .validation_error {
          display: none; }
        .validation_message {
          padding-top: 5px!important; }
        p {
          display: flex;
          align-items: center; }
        input {
          width: 170px;
          height: 39px;
          border: 1px solid white;
          outline: 0;
          text-align: center;
          background-color: rgba(13, 113, 187, 0);
          color: white;
          font-family: $book;
          font-size: 12px;
          font-weight: 700;
          line-height: 12px;
          margin: 0;
          &::placeholder {
            color: white;
            font-family: $book;
            font-size: 12px;
            font-weight: 700;
            line-height: 12px; } }
        input[type="submit"] {
          width: 111px!important;
          border: 1px solid white;
          background-color: white;
          color: $blue_dark;
          font-family: $bold;
          font-size: 12px!important;
          font-weight: 300;
          line-height: 24px;
          margin: 0!important;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            background-color: $blue_dark;
            color: white; } } } }
    &__nav {
      ul {
        display: flex;
        li {
          &:first-child {
            a {
              padding-left: 0; } }
          a {
            padding: 0 10px;
            color: white;
            font-family: $bold;
            font-size: 12px;
            font-weight: 300;
            line-height: 30px;
            text-transform: uppercase;
            transition: 0.3s;
            &:hover {
              transition: 0.3s;
              color: $blue_dark; } }
          ul {
            display: flex;
            flex-direction: column;
            li {
              text-align: center;
              a {
                padding: 0;
                color: white;
                font-family: $book;
                font-size: 14px;
                font-weight: 300;
                text-transform: none; } } } } } } } }

.footer_right {
  .title {
    color: white;
    font-family: $bold;
    font-size: 12px;
    font-weight: 300;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 21px; }
  ul {
    display: flex;
    li {
      margin: 0 10px;
      a {
        i {
          transition: 0.3s;
          font-size: 25px;
          color: white; }
        &:hover {
          transition: 0.3s;
          i {
            color: $blue_dark; } } } } } }
