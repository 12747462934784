// News header

.news_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// News list

.news_list {
  padding: 55px 0;
  &__title {
    text-align: center;
    margin-bottom: 40px;
    .title {
      text-transform: none; } }
  &__wrapper {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; } }

.news_list_item {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 260px;
  padding-bottom: 20px;
  background: #f0f0f0;
  &:first-child {
    width: 100%;
    .news_list_item__img {
      order: 1;
      width: 100%;
      max-width: 100%;
      height: 390px; }
    .news_list_item__text {
      order: 2;
      margin: 0;
      min-height: auto; }
    .news_list_item__info {
      order: 3;
      span {
        display: inline-flex;
        position: relative;
        padding-left: 12px;
        &:before {
          position: absolute;
          content: '/';
          left: 0; } } }
    .news_list_item__btn {
      order: 4;
      a {
        margin: 0; } } }
  &__text {
    padding: 0 15px;
    order: 2;
    min-height: 100px;
    p {
      margin-bottom: 0!important; } }
  &__info {
    padding: 0 15px;
    order: 3;
    margin-bottom: 22px;
    p {
      color: black;
      font-family: $book;
      font-size: 16px;
      font-weight: 300;
      font-style: italic;
      margin-bottom: 0!important; }
    span {
      display: block; } }
  &__img {
    order: 1;
    margin-bottom: 20px;
    height: 300px;
    background-color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  &__btn {
    padding: 0 15px;
    order: 4; } }

#loadMore {
  margin: 30px auto 0 auto; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
