$light: 'Arboria-Light';
$book: 'Arboria-Book';
$medium: 'Arboria-Medium';
$bold: 'Arboria-Bold';

$blue_dark: #0624ff;

body {
  margin: 0; }

* {
  box-sizing: border-box; }

.container {
  width: 1190px!important;
  max-width: 100% !important;
  margin: 0 auto;
  padding: 0; }

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none; } }

img {
  max-width: 100%;
  height: auto; }

body {
  p {
    margin: 0;
    &:last-child {
      margin-bottom: 50px; } } }

a {
  text-decoration: none; }

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 1;
  border-radius: 0;
  &::placeholder {
    line-height: 1; } }

.show_mobile {
  display: none; }

.btn_blue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 33px;
  border-radius: 17px;
  border: 1px solid $blue_dark;
  color: $blue_dark;
  font-family: $book;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    background-color: $blue_dark;
    color: white; } }

.s_title {
  color: black;
  font-family: $book;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
  text-align: center; }

.s_text {
  p {
    max-width: 100%;
    color: black;
    font-family: $book;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; } }

.title {
  margin: 0; }

.full_height {
  height: 100%; }

.fp-tableCell {
  display: flex!important;
  flex-direction: column; }

// Tabs

ul.tabs, .careers_tabs__link ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column; }

ul.tabs li, .careers_tabs__link ul li {
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 0 13px;
  position: relative;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.31);
  font-family: $book;
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  a {
    color: rgba(0, 0, 0, 0.31);
    font-family: $book;
    font-size: 10px;
    font-weight: 700;
    line-height: 24px; }
  &:before {
    position: absolute;
    content: '';
    left: 1px;
    width: 2px;
    height: 100%;
    background-color: $blue_dark; } }

ul.tabs li.current, .careers_tabs__link ul li.current {
  color: black;
  font-family: $bold;
  font-weight: 500;
  font-size: 13px;
  a {
    color: black;
    font-family: $bold;
    font-weight: 500;
    font-size: 13px; }
  &:before {
    left: 0;
    width: 5px;
    background-image: linear-gradient(-9deg, #03fbff 0%, #058fff 68%, #062bff 100%); } }

.tab-content {
  display: none; }

.tab-content.current {
  display: inherit; }

#fp-nav ul li {
  display: flex!important;
  align-items: center!important;
  margin: 0 0 2px 0!important;
  height: 28px!important;
  width: auto!important;
  .fp-tooltip {
    display: none!important; }
  a {
    display: flex;
    align-items: center;
    span {
      color: rgba(0, 0, 0, 0.31) !important;
      font-family: $book!important;
      font-size: 12px!important;
      font-weight: 700;
      line-height: 24px;
      opacity: 1!important;
      width: auto!important;
      overflow: inherit!important;
      max-width: 100% !important;
      white-space: inherit!important;
      position: initial!important;
      margin: 0 0 0 10px!important;
      background-color: inherit!important;
      height: 28px!important;
      display: flex;
      align-items: center;
      text-align: left;
      cursor: pointer;
      border-radius: 0!important;
      &:nth-child(2) {
        display: none; }
      &:before {
        position: absolute;
        content: '';
        left: 1px;
        width: 2px;
        height: 100%;
        background-color: $blue_dark; } } }
  .active {
    span {
      color: black!important;
      font-family: $medium!important;
      font-weight: 500!important;
      &:before {
        left: 0!important;
        width: 5px!important;
        background-image: linear-gradient(-9deg, #03fbff 0%, #058fff 68%, #062bff 100%)!important; } } } }

// Webinar

.webinar_video {
  text-align: center;
  iframe {
    max-width: 100%; } }
