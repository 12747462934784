// About header

.about_header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__wrapper {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 50px;
      font-weight: 300;
      line-height: 60px; } } }

// About text

.about_text {
  padding: 55px 0;
  p {
    width: 750px;
    max-width: 100%;
    margin: 0 auto 20px auto; } }

// About team

.about_team {
  position: relative;
  margin-bottom: 75px;
  .container {
    border-bottom: 1px solid #0624ff; }
  img {
    width: 100%; }
  p {
    width: 750px;
    max-width: 100%;
    margin: 0 auto;
    color: black;
    font-family: $book;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    padding: 50px 0; }
  &__title {
    text-transform: none;
    margin-bottom: 50px; }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px; }
  &__directors {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px; } }

.about_team_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 230px;
  min-height: 350px;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 15px 10px;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
    width: 17px;
    height: 17px; }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 1;
    transition: 0.3s; }
  &__info {
    transition: 0.3s;
    background-color: #e2e2e2;
    .about_team_item__close {
      transition: 0.3s;
      opacity: 1;
      img {
        opacity: 1; } }
    img {
      transition: 0.3s;
      opacity: 0; }
    .about_team_text {
      &__name,
      &__position {
        color: $blue_dark; }
      p {
        transition: 0.3s;
        opacity: 1; } }
    .about_team_item__socials {
      a {
        i {
          color: $blue_dark; } } } }
  &__socials {
    a {
      i {
        color: white;
        font-size: 22px; } } } }

.about_team_text {
  &__name,
  &__position {
    color: white;
    font-family: $bold;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 4px; }
  &__position {
    font-family: $book; }
  p {
    color: black;
    font-family: $light;
    font-size: 13px;
    font-weight: 300;
    line-height: 1;
    padding: 0;
    margin: 5px 0 0 0;
    opacity: 0;
    transition: 0.3s;
    &:last-child {
      margin-bottom: 0; } } }

.about_team_directors_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 230px;
  min-height: 330px;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 23px 26px;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 1;
    transition: 0.3s; } }

.about_team_directors_text {
  &__name,
  &__position {
    color: white;
    font-family: $bold;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 4px; }
  &__position {
    font-family: $book; }
  p {
    color: black;
    font-family: $light;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    padding: 0;
    margin-top: 5px;
    opacity: 0;
    transition: 0.3s; } }

// About investors

.about_investors {
  padding-bottom: 60px;
  &__title {
    text-align: center;
    margin-bottom: 50px;
    text-transform: none; }
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center; }
  a {
    margin: 0 auto; } }

.about_investors_item {
  width: 30%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px; }
