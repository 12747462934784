// Home header

.home_header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3); }
  .container {
    position: relative; }
  &__wrapper {
    width: 965px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      color: white;
      font-family: $bold;
      font-size: 60px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 5px; }
    p {
      color: white;
      font-family: $light;
      font-size: 25px;
      font-weight: 300;
      line-height: 25px;
      text-align: center;
      margin-bottom: 55px; }
    a {
      color: white;
      font-family: $book;
      font-size: 15px;
      background-image: url("../img/gradient_btn.svg");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50px;
      width: 170px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.3s;
      z-index: 1;
      &:before {
        position: absolute;
        content: '';
        left: 50%;
        border-radius: 50px;
        background-color: $blue_dark;
        width: 0;
        height: 100%;
        transition: 0.3s;
        z-index: -1; }
      &:hover {
        transition: 0.3s;
        color: #00d4ff;
        &:before {
          left: 0;
          width: 100%; } } } } }

// Home car

.home_car {
  padding-bottom: 50px;
  img {
    width: 100%; }
  p {
    padding: 40px 0 0 0;
    width: 740px;
    margin: 0 auto 40px auto!important;
    text-align: center; }
  &__btns {
    display: flex;
    justify-content: center;
    a {
      margin: 0 5px; } } }

// Home tabs

.home_tabs {
  position: relative;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__wrapper {
    width: 500px!important;
    justify-content: center!important;
    .careers_tabs__link {
      width: 235px; } }
  &__content {
    width: 50%; }

  .tab-content.current {
    display: flex;
    align-items: center;
    justify-content: flex-end; } }


// Home more

.home_more {
  display: flex;
  width: 100%;
  height: 60%;
  padding: 55px 0 80px;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .title {
    margin-bottom: 20px; }
  &__text {
    p {
      margin: 0 auto 50px auto;
      text-align: center;
      width: 890px;
      max-width: 100%; } }
  a {
    margin: 0 auto; } }

// Home product

.home_product {

  background-color: #f8f8f8; }

// Home logos

.home_logos {
  padding: 100px 0 45px 0;
  a {
    margin: 0 auto; }
  .swiper-logos {
    margin-bottom: 60px;
    .swiper-slide {
      text-align: center;
      img {
        height: 80px;
        margin-bottom: 25px; }
      p {
        width: 140px;
        max-width: 100%;
        margin: 0 auto;
        color: black;
        font-family: $book;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; } }
    .swiper-button-next,
    .swiper-button-prev {
      background-image: none; }
    .swiper-button-next {
      right: 0;
      text-align: right; }
    .swiper-button-prev {
      left: 0;
      text-align: left; } } }

// Video bg

#video-background {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100; }
